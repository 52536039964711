import { IPortfolio } from "@/types/portfolio";
import { MutationTree } from "vuex";
import { IPortfolioState, IPortfolioImportStatus } from "./types";
import { IPortfolioMembership } from "@/types/portfolio-memberships";
import { IPortfolioArtworkList } from "@/types/portfolio";
import router from "@/router";

export const mutations: MutationTree<IPortfolioState> = {
  addMember(state, payload: IPortfolioMembership): void {
    if (state.portfolio && state.portfolio.memberships) {
      const memberIndex = state.portfolio.memberships.findIndex(
        (m: IPortfolioMembership) => m.uuid === payload.uuid,
      );
      if (memberIndex === -1) state.portfolio.memberships.push(payload);
    }
  },
  setCurrency(state, payload: string) {
    if (state.portfolio) state.portfolio.currency = payload;
  },
  statusUpdate(state, payload: IPortfolioImportStatus) {
    state.status.push(payload);
  },
  setSearch(state, payload: string): void {
    state.search = payload;
    state.isFiltered = true;
  },
  setIsFiltered(state) {
    let isFiltered = false;
    if (window.gridApi && !window.gridApi.isDestroyed()) {
      isFiltered = Object.keys(window.gridApi.getFilterModel()).length > 0;
    }
    state.isFiltered = state.search.length > 0 || isFiltered;
  },
  setSelectedUuids(state, payload: string[]): void {
    state.selectedUuids = payload;
  },
  clear(state): void {
    state.state = "pre-load";
    state.portfolio = null;
    state.status = [];
  },
  setUpdatingStatus(
    state,
    params: {
      uuid: string;
      key:
        | "status"
        | "created_year"
        | "name"
        | "medium"
        | "edition_number"
        | "edition_total";
    },
  ): void {
    if (state.portfolio) {
      const index = state.portfolio.artwork_list.findIndex(
        (a) => a.uuid === params.uuid,
      );
      if (index > -1) {
        const artwork = state.portfolio.artwork_list[index];
        const update: IPortfolioArtworkList = [];
        // @ts-ignore
        artwork[params.key] = "Updating";
        update.push(artwork);
        state.portfolio.artwork_list.splice(index, 1, artwork);
        if (window.gridApi) window.gridApi.applyTransaction({ update });
      }
    }
  },
  reset(state): void {
    state.isFiltered = false;
    state.search = "";
    state.selectedUuids = [];
    if (window.gridApi && !window.gridApi.isDestroyed())
      window.gridApi.setFilterModel(null);
    localStorage.setItem("portfolio_filters_genders", "[]");
    state.report = "overview";
    localStorage.setItem("portfolio_report", "overview");
    state.view = "table";
    localStorage.setItem("portfolio_view", "table");
  },
  updateMember(state, payload: IPortfolioMembership): void {
    if (state.portfolio && state.portfolio.memberships) {
      const memberIndex = state.portfolio.memberships.findIndex(
        (m: IPortfolioMembership) => m.uuid === payload.uuid,
      );
      if (memberIndex > -1) {
        state.portfolio.memberships.splice(memberIndex, 1, payload);
      }
    }
  },
  removeMember(state, payload: IPortfolioMembership): void {
    if (state.portfolio && state.portfolio.memberships) {
      const memberIndex = state.portfolio.memberships.findIndex(
        (m: IPortfolioMembership) => m.uuid === payload.uuid,
      );
      if (memberIndex > -1) {
        state.portfolio.memberships.splice(memberIndex, 1);
      }
    }
  },
  setPortfolio(
    state,
    payload: { portfolio: IPortfolio | null; status: IPortfolioImportStatus[] },
  ) {
    // this makes sure we do not replace wrong data if a slow request comes back out of order
    if (
      payload.portfolio &&
      payload.portfolio.uuid === router.currentRoute.value.params.id
    ) {
      state.portfolio = payload.portfolio;
      state.state =  "idle";
      state.status = payload.status || [];
    } else if (!payload.portfolio) {
      state.status = payload.status || [];
    }
  },
  setState(state, payload: IPortfolioState["state"]) {
    state.state = payload;
  },
  setReport(state, payload: IPortfolioState["report"]) {
    // @ts-ignore this does work ...
    if (payload === "overview") this.commit("portfolio/setView", "table");
    localStorage.setItem("portfolio_report", payload);
    state.report = payload;
  },
  setView(state, payload: IPortfolioState["view"]) {
    localStorage.setItem("portfolio_view", payload);
    state.view = payload;
    state.search = "";
  },
  setPrices(state, payload: IPortfolioState["prices"]) {
    localStorage.setItem("portfolio_prices", JSON.stringify(payload));
    state.prices = payload;
  },
  removeArtworks(state, uuids: string[]) {
    uuids.forEach((uuid) => {
      if (state.portfolio) {
        const index = state.portfolio.artwork_list.findIndex(
          (a) => a.uuid === uuid,
        );
        if (index > -1) state.portfolio.artwork_list.splice(index, 1);
        if (state.portfolio.portfolio_upload_fields[state.portfolio.uuid] && state.portfolio.portfolio_upload_fields[state.portfolio.uuid][uuid]) {
          delete state.portfolio.portfolio_upload_fields[state.portfolio.uuid][uuid];
        }
      }
    });

    if (state.view === "table" && window.gridApi && !window.gridApi.isDestroyed())
      window.gridApi.applyTransaction({
        remove: uuids.map((uuid) => {
          return { uuid };
        }),
      });
  },
  moveArtworks(state, uuids: string[]) {
    uuids.forEach((uuid) => {
      if (state.portfolio) {
        const index = state.portfolio.artwork_list.findIndex(
          (a) => a.uuid === uuid,
        );
        if (index > -1) state.portfolio.artwork_list.splice(index, 1);
      }
    });
  
    if (state.view === "table" && window.gridApi && !window.gridApi.isDestroyed())
      window.gridApi.applyTransaction({
        remove: uuids.map((uuid) => {
          return { uuid };
        }),
      });
  },
  patchPortfolio(
    state,
    payload: { portfolio: IPortfolio; status: IPortfolioImportStatus[] },
  ) {
    if (state.portfolio && state.portfolio.uuid === payload.portfolio.uuid) {
      const update: IPortfolioArtworkList = [];
      const add: IPortfolioArtworkList = [];
      let forceRefresh: boolean = false;
      if ("name" in payload.portfolio)
        state.portfolio.name = payload.portfolio.name;
      if ("currency" in payload.portfolio)
        state.portfolio.currency = payload.portfolio.currency;
      if ("tags" in payload.portfolio)
        state.portfolio.tags = payload.portfolio.tags;
      if ("latest_update" in payload.portfolio)
        state.portfolio.latest_update = payload.portfolio.latest_update;
      if ("description" in payload.portfolio)
        state.portfolio.description = payload.portfolio.description;
      if ("long_description" in payload.portfolio)
        state.portfolio.long_description = payload.portfolio.long_description;
      if ("autopilot?" in payload.portfolio)
        state.portfolio["autopilot?"] = payload.portfolio["autopilot?"];
      if ("csv_url" in payload.portfolio)
        state.portfolio.csv_url = payload.portfolio.csv_url;
      if ("portfolio_uuids" in payload.portfolio)
        state.portfolio.portfolio_uuids = payload.portfolio.portfolio_uuids;
      if ("percent_premium" in payload.portfolio)
        state.portfolio.percent_premium = payload.portfolio.percent_premium;
      if ("creator" in payload.portfolio)
        state.portfolio.creator = payload.portfolio.creator;
      if ("broker_uuid" in payload.portfolio)
        state.portfolio.broker_uuid = payload.portfolio.broker_uuid;
      if ("broker_name" in payload.portfolio)
        state.portfolio.broker_name = payload.portfolio.broker_name;
      if ("insurer_uuid" in payload.portfolio)
        state.portfolio.insurer_uuid = payload.portfolio.insurer_uuid;
      if ("insurer_name" in payload.portfolio)
        state.portfolio.insurer_name = payload.portfolio.insurer_name;
      if ("settings" in payload.portfolio)
        state.portfolio.settings = payload.portfolio.settings;
      if (payload.portfolio.artwork_list) {
        payload.portfolio.artwork_list.forEach((newArtwork) => {
          const index = state.portfolio?.artwork_list.findIndex((a) => {
            return a.uuid === newArtwork.uuid;
          });
          if (typeof index === 'number' && newArtwork.ai_replacement_value !== state.portfolio?.artwork_list[index]?.ai_replacement_value) {
            forceRefresh = true;
          }
          if (index !== undefined && index > -1) {
            update.push(newArtwork);
            if (state.portfolio)
              state.portfolio.artwork_list.splice(index, 1, newArtwork);
          } else {
            add.push(newArtwork);
            if (state.portfolio)
              state.portfolio.artwork_list.unshift(newArtwork);
            state.state = "idle";
          }
        });
      }
      if (payload.portfolio.portfolio_uploads) state.portfolio.portfolio_uploads = payload.portfolio.portfolio_uploads;
      if (payload.portfolio.portfolio_upload_fields) state.portfolio.portfolio_upload_fields = payload.portfolio.portfolio_upload_fields;
      if (payload.status?.length > 0) state.status.concat(payload.status);
      if (state.view === "table" && window.gridApi && !window.gridApi.isDestroyed()) {
        window.gridApi.applyTransaction({ update });
        window.gridApi.applyTransaction({ add });
        if (forceRefresh) {
          window.gridApi.refreshCells({ force: true, columns: ['longterm_value', 'ai_viper_auction_purchase_price', 'ai_viper_artfair_market_price'] });
        }
      }
    }
  },
  setIsFocusFiltered(state, payload: boolean) {
    state.isFocusFiltered = payload;
  },
};
